<template>
  <b-modal
    id="modal-delete-resource"
    shadow
    bg-variant="white"
    backdrop
    hide-footer
    centered
  >
    <template #modal-header>
      <div class="d-flex align-items-center justify-content-start w-100 title-modal">
        <span class="modal-title">
          {{ $t('ResourceStatus.Attention') }}
        </span>
      </div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="closeModal"
      >
        ×
      </button>
    </template>

    <b-form class="pt-2">
      <b-row class="col-12 m-0 container-fields-resource">
        <b-col class="12">
          <b-form-group>
            <span>
              {{ $t('ResourceStatus.TextAttention') }}
            </span>
          </b-form-group>
        </b-col>

        <b-col
          md="12"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-form-group>
            <b-button
              v-if="!sendingForm"
              class="btn-cancel-resource"
              @click="closeModal"
            >
              {{ $t('ResourceStatus.Cancel') }}
            </b-button>
            <b-button
              v-if="!sendingForm"
              class="btn-save-resource"
              @click="deleteStatus(currentlyStatusId)"
            >
              {{ $t('ResourceStatus.Delete') }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
  import {
    BModal,
    BForm,
    BButton,
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    BFormTextarea
  } from 'bootstrap-vue';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import { mapMutations } from 'vuex';

  export default {
    components: {
      BModal,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      BFormTextarea,
      ToastificationContent
    },
    props: {
      currentlyStatusId: {
        type: String
      },
      showToast: {
        type: Function
      },
      allowEdit: {
        type: Boolean
      }
    },
    data() {
      return {
        currentSite: localStorage.getItem('currentSite'),
        sendingForm: false
      };
    },
    mounted() {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId == 'modal-delete-resource') {
          this.sendingForm = false;
        }
      });
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      closeModal() {
        this.$bvModal.hide('modal-delete-resource');
      },

      deleteStatus(id) {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        if (!this.allowEdit) {
          this.$http
            .delete(`/api/${this.$router.currentRoute.params.prefix}/currently-status/${id}`)
            .then(() => {
              this.$emit('refetch-data');
              this.showToast(
                this.$t('ResourceStatus.Success'),
                'SmileIcon',
                this.$t('ResourceStatus.MessageDeleteSuccess'),
                'success'
              );
            })
            .catch((error) => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
              this.showToast(
                this.$t('ResourceStatus.Error'),
                'FrownIcon',
                error.response.data.message,
                'danger'
              );
            });
        } else {
          this.showToast(
            this.$t('ResourceStatus.Error'),
            'FrownIcon',
            this.$t('ResourceStatus.MessageDeleteError'),
            'danger'
          );
        }
        this.closeModal();
      }
    }
  };
</script>

<style lang="scss">
  @media (min-width: 768px) {
    #modal-delete-resource {
      padding: 0 !important;

      .modal-content,
      .modal-dialog {
        max-width: 746px !important;
      }
    }
  }

  @media (max-width: 480px) {
    #modal-insert-pin {
      padding: 0 !important;
      .modal-content,
      .modal-dialog {
        max-width: 350px !important;
      }

      .modal-dialog {
        margin: 0 auto;
      }

      .modal-header {
        height: 106px !important;
        padding-bottom: 0;
        .title-modal {
          span {
            font-size: 12px !important;
          }
        }
      }
    }
  }

  #modal-delete-resource {
    .font-style-mip {
      line-height: 20px;
      font-weight: 400;
    }

    .font-weight-600 {
      font-weight: 600;
    }

    .modal-dialog {
      width: 95%;
      max-width: none;
    }

    .modal-content {
      width: 100%;
      right: 0 !important;
    }

    .modal-header {
      position: relative !important;
      height: 66px;
      background-color: #d32f2f;
      .title-modal {
        height: 100%;
      }
      .modal-title {
        line-height: 26px;
        font-weight: 600;
        font-size: 18px;
        color: #fff;
      }
    }

    .modal-body {
      padding: 0 !important;
      .btn-cancel-resource {
        background-color: #fff !important;
        border: none;
        color: #974900 !important;
        margin-right: 10px;
        transition: 0.7s;
        &:hover {
          background-color: #ffdbc4 !important;
          box-shadow: none !important;
        }
      }
      .btn-save-resource {
        background: #974900 !important;
        border: none;

        &:hover {
          box-shadow: none !important;
        }
      }

      .container-fields-resource {
        legend {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #4c4541;
        }
      }
    }
  }
</style>
