<template>
  <b-modal
    id="modal-add-resource"
    shadow
    bg-variant="white"
    backdrop
    hide-footer
    centered
  >
    <template #modal-header>
      <div class="d-flex align-items-center justify-content-start w-100 title-modal">
        <span class="modal-title">
          {{ $t('ResourceStatus.CreateResource') }}
        </span>
      </div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="closeModal"
      >
        ×
      </button>
    </template>

    <b-form class="pt-2">
      <b-row class="col-12 m-0 container-fields-resource">
        <b-col class="col-12">
          <b-form-group
            :label="$t('ResourceStatus.Name')"
            :invalid-feedback="
              flagNameEqual ? $t('ResourceStatus.StatusNameAlreadyExist') : $t('RequiredField')
            "
          >
            <b-form-input
              type="text"
              v-model="name"
              :state="stateName"
              :placeholder="stateName != false ? $t('TypeHere') : ''"
            />
          </b-form-group>
        </b-col>

        <b-col class="col-12">
          <b-form-group
            :label="$t('ResourceStatus.Description')"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-textarea
              rows="2"
              max-rows="2"
              no-resize
              type="text"
              v-model="description"
              :state="stateDescription"
              :placeholder="stateDescription != false ? $t('TypeHere') : ''"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-12 col-sm-6">
          <b-form-group
            :label="$t('ResourceStatus.Stop')"
            :invalid-feedback="$t('RequiredField')"
          >
            <b-form-select
              type="text"
              v-model="classification"
              :state="stateClassification"
              :options="classificationList"
              :style="classification == 0 ? 'color:#CFC4BE;' : ''"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col class="col-12 col-sm-6 d-flex">
          <b-form-group
            :label="$t('ResourceStatus.Color')"
            :invalid-feedback="$t('RequiredField')"
            class="container-input-color"
          >
            <the-mask
              placeholder="FF0000"
              class="form-control"
              mask="FFFFFF"
              :state="stateColor"
              :tokens="{
                F: {
                  pattern: /[0-9a-fA-F]/,
                  transform: (v) => v.toLocaleUpperCase()
                }
              }"
              id="color"
              v-model="colorHEX"
              :class="stateColor == false ? 'is-invalid' : ''"
            />
            <span
              v-if="stateColor == false"
              class="invalid-feedback"
            ></span>
          </b-form-group>
          <div class="d-flex justify-content-end align-items-center container-block-color">
            <input
              id="input-color"
              v-model="color"
              type="color"
              class="text-center rounded d-flex align-items-center justify-content-center shadow container-color"
              :style="`background-color: #${color}; ${
                stateColor == false ? 'margin-bottom: 15px;' : ''
              }`"
            />
          </div>
        </b-col>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-form-group>
            <b-button
              v-if="!sendingForm"
              class="btn-save-resource"
              @click="createCurrentlyStatus()"
            >
              {{ $t('ResourceStatus.Save') }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
  import {
    BModal,
    BForm,
    BButton,
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormSelect
  } from 'bootstrap-vue';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import { TheMask } from 'vue-the-mask';

  export default {
    components: {
      BModal,
      BForm,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      BFormTextarea,
      BFormSelect,
      TheMask,
      ToastificationContent
    },
    props: ['showToast', 'classificationList', 'nameStatus'],
    data() {
      return {
        currentSite: localStorage.getItem('currentSite'),
        name: '',
        description: '',
        color: '',
        colorHEX: '',
        classification: 0,
        stateClassification: null,
        stateColor: null,
        stateName: null,
        stateDescription: null,
        sendingForm: false,
        flagNameEqual: false
      };
    },
    created() {},
    mounted() {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId == 'modal-add-resource') {
          this.classification = 0;
          this.name = '';
          this.description = '';
          this.color = '';
          this.stateColor = null;
          this.stateName = null;
          this.stateDescription = null;
          this.stateClassification = null;
          this.sendingForm = false;
        }
      });
    },
    methods: {
      closeModal() {
        this.$bvModal.hide('modal-add-resource');
      },
      validaCampos() {
        let nameEqual = this.nameStatus.find((item) => item == this.name);
        if (nameEqual && this.name) {
          this.flagNameEqual = true;
        } else {
          this.flagNameEqual = false;
        }

        this.stateColor = this.color ? null : false;
        this.stateName = this.name && this.flagNameEqual == false ? null : false;
        this.stateDescription = this.description ? null : false;
        this.stateClassification = this.classification ? null : false;
      },
      createCurrentlyStatus() {
        this.validaCampos();
        if (
          this.stateColor != false &&
          this.stateName != false &&
          this.stateDescription != false &&
          this.stateClassification != false
        ) {
          this.sendingForm = true;
          this.$http
            .post(`/api/${this.$router.currentRoute.params.prefix}/currently-status/false`, {
              CurrentlyStatus: [
                {
                  site_guid: this.currentSite,
                  language: 'PT',
                  name: this.name,
                  description: this.description,
                  color: this.color,
                  allow_edit: true,
                  status_classification: this.classification
                }
              ]
            })
            .then((res) => {
              this.sendingForm = false;
              this.$emit('refetch-data');
              this.showToast(
                this.$t('ResourceStatus.Success'),
                'SmileIcon',
                this.$t('ResourceStatus.MessageAddSuccess'),
                'success'
              );
              this.closeModal();
            })
            .catch((error) => {
              this.sendingForm = false;
              this.showToast(
                this.$t('ResourceStatus.Error'),
                'FrownIcon',
                error.response.data.message,
                'danger'
              );
            });
        }
      }
    },
    watch: {
      color(v) {
        this.colorHEX = v.replace('#', '');
      },
      colorHEX(v) {
        this.color = '#' + v;
      }
    }
  };
</script>

<style lang="scss">
  @media (min-width: 768px) {
    #modal-add-resource {
      padding: 0 !important;

      .modal-content,
      .modal-dialog {
        max-width: 746px !important;
      }
    }
  }

  @media (max-width: 480px) {
    #modal-add-resource {
      padding: 0 !important;
      .modal-content,
      .modal-dialog {
        max-width: 350px !important;
      }

      .modal-dialog {
        margin: 0 auto;
      }

      .modal-header {
        padding-bottom: 0;
        .title-modal {
          span {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  #modal-add-resource {
    .custom-select option:not(:checked) {
      color: #4c4541;
    }

    #input-color {
      -webkit-appearance: none;
      border: none;
      width: 38px;
      height: 38px;
    }

    #input-color::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    #input-color::-webkit-color-swatch {
      border: none;
      border-radius: 6px;
    }

    .container-input-color {
      width: 85%;
    }

    .container-block-color {
      margin-left: 8px;
    }

    .container-color {
      height: 38px;
      width: 38px;
      margin-top: 7px;
      border: 1px solid #000;
    }
    .form-control.is-invalid {
      background-image: none !important;
    }

    .invalid-feedback {
      margin-left: 5px !important;
      font-size: 10px;
    }

    .custom-select {
      background-image: url(../../../assets/images/pages/arrow-down.png);
    }

    .font-style-mip {
      line-height: 20px;
      font-weight: 400;
    }

    .font-weight-600 {
      font-weight: 600;
    }

    .modal-dialog {
      width: 95%;
      max-width: none;
    }

    .modal-content {
      width: 100%;
      right: 0 !important;
    }

    .modal-header {
      position: relative !important;
      height: 66px;
      background-color: #eceff1;
      .title-modal {
        height: 100%;
      }
      .modal-title {
        line-height: 26px;
        font-weight: 600;
        font-size: 18px;
      }
    }

    .modal-body {
      padding: 0 !important;
      .btn-save-resource {
        background: #974900 !important;
        border: none;
      }

      .container-fields-resource {
        legend {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #4c4541;
        }
      }
    }
  }
</style>
